import * as React from 'react'
import { isIOS } from "react-device-detect";

/**
 * Inline video component based on target webbrowser for iOS safari
 * or everything else based on "react-device-detect" [DENKUNDMACH. VAM]
 * @param {input video to return with inline video element} video 
 * @returns a mobile inline video component for iOS and everything else
 */
const InlineMobileVideo = (video) => {
    // Nutzt ein Video Element, außer bei IOS, dor wird ein Image mit Video Src gerendert
    let renderElem = (<video/>);
    if (isIOS)
        renderElem = (<img/>);

    // Nimmt das Basiselement + props für Autoplay
    let elem = React.cloneElement(
        renderElem, {
            className: "mediacontent",
            style:{ width: '100%',  flex: '100%'},
            playsInline: "",
            muted: "true",
            autoPlay: "autoplay",
            controls: "",
            loop: "true",
            src: video
        }
    );

    return (
        <div className={"inlineMobileVideo"}>
            { elem }
        </div>
    )
}
export default InlineMobileVideo
