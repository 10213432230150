import * as React from "react";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {Helmet} from "react-helmet";

//Components
import Layout from "../../components/layout";
import { abschnitt as Abschnitt, trenner as Trenner } from "../../components/layoutComponents";
import Header from "../../components/header/header";
import { IntroBase } from "../../components/identity";
import ProduktInfo from "../../components/projects/ProjectHeader";
import ProductInfoDataEins from "../../components/projects/ContentBlock1S";
import ProductInfoDataZwei from "../../components/projects/ContentBlock2S";
import FlyingContactClick from "../../components/contactFlyingButton_click";

//CSS
import "../../components/teaser/teaser.css"
import "../../components/contactFlyingButton.css";

//DATA
import teaser_mobile from "../../images/Produkte/bild_mobile.jpg";

import { bild1, bild2, bild3, bild4, bild5 } from "../../images/Produkte";
import { video1, video2, video3 } from "../../videos/teaser";
import {graphql} from "gatsby";

const helm = {
    title: 'DENKUNDMACH - Augmented Livingspaces',
    url: 'https://denkundmach.com',
    description: 'AUGMENTED LIVINGSPACES.',
}

const Product3 = (location) => {
  const breakpoints = useBreakpoint();

  return (
      <>
          <Helmet htmlAttributes={{
              lang: 'de',
          }}>
              <meta charSet="utf-8" />
              <title>{helm.title}</title>
              <link rel="canonical" href={helm.url} />
              <meta name="description" content={helm.description} />
              <meta property="og:type" content="article" />
              <meta property="og:title" content={helm.title} />
              <meta property="og:description" content={helm.description} />

              <script type="application/ld+json">
                  {`
                      {
                          "@context": "https://schema.org/",
                          "@type": "Product",
                          "name": "Augmented Livingspaces",
                          "image": "",
                          "description": "abc"
                      }
                  `}
              </script>
          </Helmet>

          <Layout>
              <Header location={location} />
              {breakpoints.md ? <></> :  <Trenner verysmall={true}></Trenner> }

              {/* HEADER INFOS***************       */}
              {breakpoints.sm ? <IntroBase image_mobile={teaser_mobile} /> : <></>}
              <Abschnitt>
                  {breakpoints.sm ? <></> : <IntroBase image={bild1} />}

                  <ProduktInfo
                      titel={"Augmented Livingspaces"}
                      untertitel={
                          "Eine interaktive VR-Installation mit über 350 digital aufbereiteten Exponaten "
                      }
                      text={
                          "Aufbauend auf das Projekt “Hängende Gärten” entstand das Forschungsprojekt “DIGITALES MUSEUM DER MODERNE”, dass als Teil der Ausstellung „DAS COMEBACK – Bauhaus Meister Moderne“ im Kunstmuseum Moritzburg Halle (Saale) ausgestellt wurde. Für die interaktive VR-Installation wurden über 350 Exponate digital aufbereitet und 80 Objekte mittels Photogrammetrie dreidimensional erfasst. Als Teil des “Team Stadtkrone” ist der Fokus unserer Arbeit die Interaktion innerhalb der VR-Anwendung, sowie die interaktive Darstellung der Provenienzdaten zu den Exponaten."
                      }
                      entstehung={"April 2019 bis September 2019"}
                      projektrahmen={
                          "eam Stadtkrone.VR der Burg Giebichenstein Kunsthochschule Halle in Kooperation mit dem Kunstmuseum Moritzburg Halle (Saale)"
                      }
                      links={[
                          {
                              text: "www.stadtkrone-halle.de",
                              href: "www.stadtkrone-halle.de",
                          },
                          {
                              text: "www.kunstmuseum-moritzburg.de/bauhaus-museum",
                              href: "www.kunstmuseum-moritzburg.de/bauhaus-museum",
                          },
                      ]}
                  />
              </Abschnitt>

              {/* CONTENT***************       */}
              <Abschnitt line={true} headline={""}>
                  <Trenner small={true}/>

                  <ProductInfoDataEins
                      image={bild1}
                      unterschrift={"Photogrammetrien in der Sammlung zum Kunsthandwerk"}
                      text={
                          "Die Objekte wurden in verschiedenen Sammlungen gegliedert, die jeweils einen thematischen Fokus hatten oder das Schaffen eines bestimmten Künstlers widerspiegeln."
                      }
                  />

                  <Trenner small={true}/>

                  <ProductInfoDataEins
                      video={video1}
                      unterschrift={
                          "Vogelperspektiven des Museumsgebäudes mit ausgestellten Sammlungen"
                      }
                      text={
                          "Das im Rahmen des Stadtkrone Projekts umgesetzte Kunstmuseum auf Basis des Entwurfs vom Bauatelier Prof. Walter Gropius, diente mit seinen ca. 3000 qm als Ausstellungsfläche für das digitale Museum."
                      }
                  />

                  <Trenner small={true}/>

                  <ProductInfoDataEins
                      video={video2}
                      unterschrift={"Darstellung von Provenienzdaten der Werke in VR"}
                      text={
                          "Die Fülle an Werken wurde durch eine Informationsebene ergänzt, in der die teils sehr umfangreichen Provenienzdaten der Werke hervorgehoben wurden. Das Ziel war eine VR-gerechte Lesbarkeit, die trotz enger Reihungen der gehängten Bilder genügend Platz hat zu wirken. Durch das Verweilen des Blicks auf einem Informationssymbol, wird das fokussierte Werk hervorgehoben, während das umliegende Museum ausgeblendet wird. Die über eine Datenbank geladenenen Provenienztexte konnte sich auf diese Weise frei im Raum ausbreiten."
                      }
                  />

                  <Trenner small={true}/>

                  <ProductInfoDataZwei
                      image1={bild2}
                      image2={bild3}
                      unterschrift1={
                          "Präsentation in der Landesvertretung Sachsen-Anhalts in Brüssel mit Thomas Bauer-Friedrich (Direktor der Moritzburg) und Sophie Parschat"
                      }
                      unterschrift2={"Vorführung des digitalen Museums in Brüssel"}
                      text={
                          "Das BAUHAUS-MUSEUM war nicht nur im Rahmen der Ausstellung „DAS COMEBACK – Bauhaus Meister Moderne“ im Kunstmuseum Moritzburg Halle (Saale) ausgestellt, sondern wurde auch auf mehreren Events zugänglich gemacht. Unter anderem in der Landesvertretung Sachsen-Anhalts in Brüssel."
                      }
                  />
              </Abschnitt>

              <FlyingContactClick />
          </Layout>
      </>
  );
};

export default Product3;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
