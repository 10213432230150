import * as React from 'react';
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";

import './identity.css';

import vw from './../../images/Logos_Master_0000s_0000_vw_sw.png';
import tdg from './../../images/Logos_Master_0002s_0000_tdg_sw.png';
import lisa from './../../images/Logos_Master_0001s_0000_lisa_sw.png';
import feininger from './../../images/Logos_Master_0003s_0000_feininger_sw.png';
import mlu from './../../images/Logos_Master_0004s_0000_mlu_sw.png';
import vraktion from './../../images/Logos_Master_0005s_0000_vraktion_sw.png';
import moritz from './../../images/Logos_Master_0007s_0000_moritzburg_sw.png';

/***
 * Container für Partner Logos
 * @returns {JSX.Element}
 * @constructor
 */
const Clients = () => {
    const { t } = useTranslation('client');
    return (
        <div>
            <div className={'client-container'}>
                {/*Translation inline!*/}
                <div className={'client-and-partner'} dangerouslySetInnerHTML={{__html: t('KUNDEN UND PARTNER')}}/>
                <div className={'line-to-head'}/>
            </div>
            <div className={"gallery"}>
                <img src={vw} className={'client-logo'} alt="volkswagen"/>
                <img src={lisa} className={'client-logo'} alt="LISA"/>
                <img src={tdg} className={'client-logo'} alt="TDG"/>
                <img src={feininger} className={'client-logo'} alt="Feininger"/>
                <img src={mlu} className={'client-logo'} alt="Martin-Luther-Universität Halle-Wittenberg"/>
                <img src={vraktion} className={'client-logo'} alt="VR Aktion"/>
                <img src={moritz} className={'client-logo'} alt="Kunstmuseum Moritzburg Halle"/>
            </div>
        </div>
    )
}
export default Clients
