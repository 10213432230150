import * as React from 'react'
import {isMobile} from "react-device-detect";
import InlineMobileVideo from '../generics/inlineVideo';

import HeaderVideo from "./../../videos/header/Header_DENKUNDMACH_web_1920.mp4"
import HeaderVideo_mobile from "./../../videos/header/Header_DENKUNDMACH_portrait_web_640.mp4"
import {useTranslation} from "gatsby-plugin-react-i18next";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {graphql} from "gatsby";

/***
 * Intro Container der Startseite mit Videocontainer und Textoverlay
 * @returns {JSX.Element}
 * @constructor
 */
const Intro = () => {
    const { t } = useTranslation('intro');
    const breakpoints = useBreakpoint();

    let videoContainer;

    if (breakpoints.xs === undefined || breakpoints.sm === undefined) {
        videoContainer = (<></>);
    }
    else if (breakpoints.sm === true || breakpoints.xs === true) {
        videoContainer = (
            InlineMobileVideo(HeaderVideo_mobile)
        );
    }
    else {
        // Anderen Videoplayer falls mobile
        videoContainer =
            (<video
                className="mediacontent"
                style={{ width: '100%'}}
                autoPlay="autoplay"
                controls=""
                loop
                muted
                src={HeaderVideo}
            />);
    }

    return (
        <div className={"intro"}>
            {/* Textoverlay über dem Videoelement | Translation inline!*/}
            <div className="intro-overlay" dangerouslySetInnerHTML={{__html: t('intro overlay')}}/>

            {videoContainer}
        </div>  
    )
}
export default Intro
